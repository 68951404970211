import React from "react";
import loading from "../../assets/loading.svg";
import {Grid} from "@mui/material";

const Loading = () => (
    <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{textAlign: 'center'}}>
        <Grid item xs={12} sm={10} md={8} lg={6}>
            <div className="spinner">
                <img src={loading} alt="Loading"/>
            </div>
        </Grid>
    </Grid>
);

export default Loading;
