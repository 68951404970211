import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../../common/Loading";
import React from "react";
import {Grid} from "@mui/material";

function ReservationProcessing() {

    return (
        <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{textAlign: 'center'}}>
            <Grid item xs={12} sm={10} md={8} lg={6}>
                We verwerken uw aanvraag.
                <Loading/>
            </Grid>
        </Grid>
    )
}

export default withAuthenticationRequired(ReservationProcessing, {
    onRedirecting: () => <Loading/>,
    returnTo: '/payment-processor'
});
