import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../../common/Loading";
import React from "react";
import {Button, Card, CardActions, CardContent, CardHeader, Grid, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

function ReservationPaid() {
    const navigate = useNavigate();
    const {user} = useAuth0();

    return (
        <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{textAlign: 'center'}}>
            <Grid item xs={12}>
                <Card variant="outlined">
                    <CardHeader title={<Typography color="secondary" variant="h5">Betaling met succes uitgevoerd.</Typography>}/>
                    <CardContent>
                        <Typography variant='p'>Een bevestigingsmail zal verzonden worden naar</Typography>
                        <Typography variant='p'> {user.email}</Typography>
                    </CardContent>
                    <CardActions style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button variant="contained" onClick={() => navigate('/mijn-reservaties')}>
                            Reservatie overzicht
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
)
}

export default withAuthenticationRequired(ReservationPaid, {
    onRedirecting: () => <Loading/>,
        returnTo
:
    '/nieuwe-reservatie'
});
