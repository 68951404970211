import React, {useContext, useEffect, useState} from "react";
import {Box, Button, Card, CardActions, CardContent, Grid, List, ListItem, Tooltip, Typography} from "@mui/material";
import {useReservationAPI} from "../../service/useReservationAPI";
import {GlobalStateContext} from "../../state/GlobalStateProvider";
import PetsIcon from "@mui/icons-material/Pets";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../common/Loading";
import {useNavigate} from "react-router-dom";
import GetErrorSnackbar from "../../common/Snackbar";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function ReservationOverview({updateCurrentStep}) {
    let navigate = useNavigate();
    const {getReservationByUuid, confirmReservation, getPaymentLink} = useReservationAPI();
    const {globalState, updateGlobalState} = useContext(GlobalStateContext);
    const [reservation, setReservation] = useState({
        bookingNumber: '',
        price: '',
        cats: [],
        arrival: '',
        departure: '',
        practice: '',
    });

    function fetch() {
        setIsLoading(true);
        getReservationByUuid(globalState.reservationUuid)
            .then(value => {
                setReservation(value)
            })
            .catch(reason => handleFetchError('Reservatie overzicht ophalen mislukt'))
            .finally(() => setIsLoading(false));
    }

    const [isLoading, setIsLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    function handleFetchError(reason) {
        setSnackbarMessage(reason)
        setOpenSnackbar(true)
    }

    useEffect(() => {
        fetch();
    }, [])

    function goBack() {
        updateCurrentStep(6)
    }

    function getPaymentLinkCall() {
        getPaymentLink(globalState.reservationUuid)
            .then(value => {
                // Before redirecting to payment provider
                localStorage.setItem('paymentState', {state: 'OPEN', uuid: JSON.stringify(globalState.reservationUuid)});
                window.location.href = value;
                //updateGlobalState({catsForReservation: []})
                // navigate('/mijn-reservaties')
            })
            .catch(reason => handleFetchError('Betaallink ophalen mislukt'))
    }

    return (
        <>
            {isLoading && (<Loading/>)}
            {!isLoading && (
                <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{textAlign: 'center'}}>
                    <Grid item xs={12} sm={10} md={8} lg={6}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h5" color="secondary" display="block">
                                    <h1>Reservatie overzicht</h1>
                                </Typography>
                                <Typography variant="p" color="secondary" display="block">
                                    <b>Reservatie nummer</b>
                                </Typography>
                                <Typography variant="p" color="secondary" display="block" style={{marginBottom: '15px'}}>
                                    {reservation.bookingNumber}
                                </Typography>
                                <Typography variant="p" color="secondary" display="block">
                                    <b>Reservatie voor periode</b>
                                </Typography>
                                <Typography variant="p" color="secondary" display="block">
                                    {reservation.fromDate} - {reservation.toDate}</Typography>
                                <Typography variant="p" color="secondary" display="block" style={{marginTop: '15px'}}>
                                    <b>Dierenartsen praktijk</b>
                                </Typography>
                                <Typography variant="p" color="secondary" display="block" style={{marginBottom: '15px'}}>
                                    {reservation.practice}
                                </Typography>

                                <Typography variant="p" color="secondary" display="block">
                                    <b>Reservatie voor</b>
                                </Typography>
                                {reservation.cats.map((cat, index) => (
                                    <Typography variant="p" color="secondary" display="block"><PetsIcon color="primary"/>{cat.name}</Typography>
                                ))}
                                <Typography variant="p" color="secondary" display="block">
                                    <b>Check-in</b>
                                </Typography>
                                <Typography variant="p" color="secondary" display="block" style={{marginBottom: '15px'}}>
                                    {reservation.checkIn}
                                </Typography>
                                <Typography variant="p" color="secondary" display="block">
                                    <b>Check-out </b>
                                </Typography>
                                <Typography variant="p" color="secondary" display="block" style={{marginBottom: '15px'}}>
                                    {reservation.checkOut}
                                </Typography>
                                <Typography variant="p" color="secondary" display="block">
                                    <b>Geselecteerd verblijf</b>
                                </Typography>
                                <Typography variant="p" color="secondary" display="block" style={{marginBottom: '15px'}}>
                                    {reservation.stayTypes}
                                </Typography>
                                <Tooltip title="service fee 1,5€ inbegrepen">
                                    <Box display="inline-flex" alignItems="center">
                                        <Typography variant="body1" color="main" display="block">
                                            <b>Prijs van de reservatie</b>
                                        </Typography>
                                        <InfoOutlinedIcon style={{ marginLeft: 4 }} />
                                    </Box>
                                </Tooltip>
                                <Typography variant="p" color="secondary" display="block" style={{marginBottom: '15px'}}>
                                    {reservation.price} €
                                </Typography>
                                <Typography variant="p" color="secondary" display="block">
                                    <b>Voorschot</b>
                                </Typography>
                                <Typography variant="p" color="secondary" display="block" style={{marginBottom: '15px'}}>
                                    {reservation.advance} €
                                </Typography>
                            </CardContent>
                            <CardActions style={{
                                display: 'flex', justifyContent: 'space-between'
                            }}>
                                <Button onClick={goBack} variant="contained">Vorige</Button>
                                <Button variant="contained" onClick={getPaymentLinkCall}>Bevestig uw reservatie</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <GetErrorSnackbar openSnackbar={openSnackbar} snackbarMessage={snackbarMessage} setOpenSnackbar={setOpenSnackbar}/>
                </Grid>)}
        </>
    )
}

export default withAuthenticationRequired(ReservationOverview, {
    onRedirecting: () => <Loading/>,
    returnTo: '/nieuwe-reservatie'
});
