import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    useMediaQuery,
    useTheme,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Box,
    Typography,
    IconButton,
    AppBar,
    Toolbar,
    Badge,
    ListItemButton,
    Button
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import PetsIcon from '@mui/icons-material/Pets';
import PhotoIcon from '@mui/icons-material/Photo';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Authentication from "../../Authentication";
import {useReservationAdminAPI} from "../../service/useReservationAdminAPI";
import {useAuth0} from "@auth0/auth0-react";
import GetErrorSnackbar from '../../common/Snackbar';
import {useCatAPI} from "../../service/useCatAPI";
import {GlobalStateContext} from "../../state/GlobalStateProvider";


const SideNav = () => {
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [newReservationCount, setNewReservationCount] = useState(0);
    const {getCountNewReservations} = useReservationAdminAPI();
    const {getCats} = useCatAPI();
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {loginWithRedirect, getAccessTokenSilently, isAuthenticated, logout} = useAuth0();
    const {user} = useAuth0();
    const isAdmin = user && user['https://tailvation.com/app_metadata']?.roles?.includes('cat-admin');
    const [showNavigation, setShowNavigation] = useState(false);
    const {updateGlobalState, setPartOfGlobalState} = useContext(GlobalStateContext);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        const checkTokenExpiry = async () => {
            try {
                await getAccessTokenSilently();
            } catch (error) {
                await loginWithRedirect();
            }
        };

        if (isAuthenticated) {
            checkTokenExpiry();

            const interval = setInterval(() => {
                checkTokenExpiry();
            }, 60 * 1000); // Check every minute

            return () => clearInterval(interval);

        }
    }, [isAuthenticated, getAccessTokenSilently, loginWithRedirect, logout]);

    useEffect(() => {
        if (isAdmin) {
            getCountNewReservations()
                .then(value => setNewReservationCount(value))
                .catch(reason => {
                    setSnackbarMessage('Aantal nieuwe reservaties kon niet opgehaald worden');
                    setOpenSnackbar(true)
                })
        }
    }, [isAdmin]);

    useEffect(() => {
        if (isAuthenticated) {
            getCats()
                .then(values => {
                    if (values && values.length > 0 && values[0]?.age) {
                        setShowNavigation(true)
                    }
                })

        }
    })

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleNavigation = (to) => {
        if (isMobile) {
            setDrawerOpen(false)
        }
        navigate(to)
    }

    function clearReservationMetaAndNavigate() {
        setPartOfGlobalState(setPartOfGlobalState('reservationMeta', {
            fromDate: undefined,
            toDate: undefined,
            catsInReservationIds: [],
            checkInHour: undefined,
            checkOutHour: undefined,
            stayTypes: []
        }))
        navigate('/nieuwe-reservatie');
    }

    const drawerContent = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%', // Full height of the parent container
            }}
        >
            {/* Top Section */}
            <Box
                sx={{flexGrow: 1, padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}
            >
                <a href={'https://www.kattenhotel-catland.be'}><img src="https://kattenhotel-catland.be/onewebmedia/Logo_kattenhotel_catland_web.png" alt="Company Logo"
                                                                    style={{maxWidth: '100%', maxHeight: '60px'}}/></a>
                <Typography color="secondary">
                    Kattenhotel Catland
                </Typography>
                {showNavigation && (<List>
                    <ListItemButton onClick={() => handleNavigation("/mijn-reservaties")}>
                        <ListItemIcon><HomeIcon color="primary"/></ListItemIcon>
                        <ListItemText primary="Mijn Reservaties"/>
                    </ListItemButton>
                    <ListItemButton onClick={() => handleNavigation("/mijn-katten")}>
                        <ListItemIcon><PetsIcon color="primary"/></ListItemIcon>
                        <ListItemText primary="Mijn Katten"/>
                    </ListItemButton>
                    <ListItemButton onClick={() => handleNavigation("/mijn-dierenarts")}>
                        <ListItemIcon><HealthAndSafetyIcon color="primary"/></ListItemIcon>
                        <ListItemText primary="Mijn Dierenarts"/>
                    </ListItemButton>
                    <ListItemButton onClick={() => handleNavigation("/mijn-profiel")}>
                        <ListItemIcon><AccountCircleIcon color="primary"/></ListItemIcon>
                        <ListItemText primary="Mijn Profiel"/>
                    </ListItemButton>
                    <ListItemButton onClick={() => handleNavigation("/noodcontact")}>
                        <ListItemIcon><AccountCircleIcon color="primary"/></ListItemIcon>
                        <ListItemText primary="Noodcontact"/>
                    </ListItemButton>
                    {/*<ListItem onClick={() => handleNavigation("/mijn-fotos")}>*/}
                    {/*    <ListItemIcon><PhotoIcon color="primary"/></ListItemIcon>*/}
                    {/*    <ListItemText primary="Mijn Foto's"/>*/}
                    {/*</ListItem>*/}
                    {isAdmin && (<ListItemButton onClick={() => handleNavigation("/reservatie-beheer")}>
                        <ListItemIcon><PhotoIcon color="primary"/></ListItemIcon>
                        <ListItemText primary="Reservatie overzicht"/>
                    </ListItemButton>)}
                    {isAdmin && (<ListItemButton onClick={() => handleNavigation("/pick-delivery-schedules")}>
                        <ListItemIcon><PhotoIcon color="primary"/></ListItemIcon>
                        <ListItemText primary="PickUpDeliverySchedule"/>
                    </ListItemButton>)}
                    {/*<ListItem button onClick={() => handleNavigation("/checkout")}>*/}
                    {/*    <ListItemIcon><PhotoIcon color="primary"/></ListItemIcon>*/}
                    {/*    <ListItemText primary="Checkout"/>*/}
                    {/*</ListItem>*/}
                    {isAdmin && (<ListItemButton onClick={() => handleNavigation("/reservatie-overzicht")}>
                        <ListItemIcon><PhotoIcon color="primary"/></ListItemIcon>
                        <ListItemText primary="Nieuwe reservaties"/>

                        {newReservationCount > 0 && <Badge badgeContent={newReservationCount} style={{marginLeft: 16}} color="primary"/>}
                    </ListItemButton>)}
                </List>)}

                {showNavigation && (<Button variant="contained" onClick={() => clearReservationMetaAndNavigate()}>
                    Maak een reservatie
                </Button>)}
            </Box>

            {/* Bottom Section */}
            <Box
                sx={{
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderTop: '1px solid', // Optional divider style
                    borderColor: 'rgba(0, 0, 0, 0.12)' // Optional divider color
                }}
            >
                {/* Authentication Section */}
                <Authentication/>
            </Box>
        </Box>
    );

    return (
        <div>
            {isMobile && (
                <AppBar position="fixed" sx={{background: 'transparent', boxShadow: 'none'}}>
                    <Toolbar>
                        <IconButton
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
            )}
            <Drawer
                variant={isMobile ? "temporary" : "permanent"}
                open={drawerOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                {drawerContent}
            </Drawer>
            {isMobile && <Toolbar/>} {/* Additional toolbar to prevent content overlap under the AppBar */}
            <GetErrorSnackbar openSnackbar={openSnackbar} snackbarMessage={snackbarMessage} setOpenSnackbar={setOpenSnackbar}/>
        </div>
    );
}

export default SideNav;
