import {useAuth0} from "@auth0/auth0-react";

export function useReservationAdminAPI() {
    const {getAccessTokenSilently} = useAuth0();

    const fetchWithAuth = async (url, options = {}) => {
        const accessToken = await getAccessTokenSilently();
        const headers = new Headers(options.headers || {});
        headers.append('Authorization', `Bearer ${accessToken}`);
        return fetch(url, {...options, headers});
    };

    const getAllReservationForMonthAndYear = async (month, year) => {
        try {
            const queryParams = new URLSearchParams({month, year}).toString();
            const url = `${process.env.REACT_APP_BACK_API}/reservations/admin?${queryParams}`;

            const response = await fetchWithAuth(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error checking reservation getAllReservationForMonthAndYear:', error);
            throw error;
        }
    };

    const getNewReservations = async () => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations/admin/new`;

            const response = await fetchWithAuth(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error checking reservation getNewReservations:', error);
            throw error;
        }
    };

    const getCountNewReservations = async () => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations/admin/new/count`;

            const response = await fetchWithAuth(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error checking reservation getCountNewReservations:', error);
            throw error;
        }
    };

    const validateReservation = async (reservationUuid) => {

        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations/admin/${reservationUuid}`;
            const response = await fetchWithAuth(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.text();
        } catch (error) {
            throw error;
        }
    };

    const cancelReservation = async (reservationUuid) => {

        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations/admin/${reservationUuid}`;
            const response = await fetchWithAuth(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.text();
        } catch (error) {
            console.error('Error booking reservation:', error);
            throw error;
        }
    };

    const getReservationPerDayForMonthAndYear = async (year, month) => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations/admin?year=${year}&month=${month + 1}`;
            const response = await fetchWithAuth(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error checking reservation getReservationPerDayForMonthAndYear:', error);
            throw error;
        }
    }

    const getReservationsForDay = async (date) => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations/admin/day?date=${formatDate(date)}`;
            const response = await fetchWithAuth(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error checking reservation getReservationsForDay:', error);
            throw error;
        }
    }

    const getPersonDetail = async (uuid) => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations/admin/person/${uuid}`;
            const response = await fetchWithAuth(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error checking reservation getPersonDetail:', error);
            throw error;
        }
    }

    const getPetDetail = async (uuid) => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations/admin/pet/${uuid}`;
            const response = await fetchWithAuth(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error checking reservation getPetDetail:', error);
            throw error;
        }
    }

    const getVetDetail = async (uuid) => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations/admin/vet/${uuid}`;
            const response = await fetchWithAuth(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error checking reservation getVetDetail:', error);
            throw error;
        }
    }

    const getPickUpDeliverSchedules = async () => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations/admin/pickUpDeliverSchedules`;
            const response = await fetchWithAuth(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error checking reservation getVetDetail:', error);
            throw error;
        }
    }

    const updatePickUpDeliverSchedules = async (pickuUpDeliverySchedules) => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations/admin/pickUpDeliverSchedules`;
            const accessToken = await getAccessTokenSilently();
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    "Content-type": 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(pickuUpDeliverySchedules)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error when updating deliverDaySchedules:', error);
            throw error;
        }
    }

    const updateReservation = async (reservation) => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations/admin/${reservation.uuid}`;
            const accessToken = await getAccessTokenSilently();
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    "Content-type": 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(reservation)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return true;
        } catch (error) {
            console.error('Error when updating reservation:', error);
            throw error;
        }
    }

    const downloadContracts = async (uuid) => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations/admin/contract/${uuid}`;
            const response = await fetchWithAuth(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Convert the response to a Blob
            const blob = await response.blob();

            // Check for the Content-Disposition header for filename
            const contentDisposition = response.headers.get('content-disposition');
            let filename = 'contract.pdf'; // default filename

            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                if (filenameMatch.length > 1) {
                    filename = filenameMatch[1];
                }
            }

            // Create a download link for the Blob
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = filename; // Set the filename
            document.body.appendChild(link);
            link.click();

            // Cleanup
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);

        } catch (error) {
            console.error('Error downloading contracts:', error);
            throw error;
        }
    }

    function formatDate(date) {
        const year = date.getFullYear();
        // Add 1 because getMonth() returns month from 0-11
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    return {
        getAllReservationForMonthAndYear,
        getNewReservations,
        validateReservation,
        cancelReservation,
        getCountNewReservations,
        getReservationsForDay,
        getReservationPerDayForMonthAndYear,
        getPersonDetail,
        getPetDetail,
        getVetDetail,
        getPickUpDeliverSchedules,
        updatePickUpDeliverSchedules,
        updateReservation,
        downloadContracts
    };
}
