import {useAuth0} from "@auth0/auth0-react";

export function useVeterinaryAPI() {
    const {getAccessTokenSilently} = useAuth0();

    const getVeterinary = async () => {
        try {
            const accessToken = await getAccessTokenSilently();

            const response = await fetch(`${process.env.REACT_APP_BACK_API}/veterinary`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching personal information:', error);
            throw error; // Rethrow the error if you want to handle it outside of the function
        }
    };

    const getVeterinaryByUuid = async (uuid) => {
        try {
            const accessToken = await getAccessTokenSilently();

            const response = await fetch(`${process.env.REACT_APP_BACK_API}/veterinary/${uuid}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching personal information:', error);
            throw error; // Rethrow the error if you want to handle it outside of the function
        }
    };

    const updateVeterinary = async (veterinary) => {
        try {
            const accessToken = await getAccessTokenSilently();

            const response = await fetch(`${process.env.REACT_APP_BACK_API}/veterinary`, {
                method: 'PUT',
                headers: {
                    "Content-type": 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(veterinary)
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching personal information:', error);
            throw error; // Rethrow the error if you want to handle it outside of the function
        }
    };

    return {getVeterinary, updateVeterinary, getVeterinaryByUuid};
}
