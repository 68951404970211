// theme.js
import {createTheme} from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#027793'
        },
        secondary: {
            main: '#020f1a',
        },
        background: {
            default: 'rgba(234,234,234,0.42)',
        },
        backgroundCatlandLight: {
            default: '#dbffff',
        },
        typography: {
            h1: {
                fontSize: '2rem',
                // other styles
            },
        },
        button: {backgroundColor: '#F9BC99', color: "black"}

        // ... Add other colors or theme properties as needed
    },
    typography: {
        // Define typography here
    },
    // ... Add other theme customizations as needed
});

export default theme;
