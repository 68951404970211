import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../common/Loading";
import React, { useContext, useEffect, useState } from "react";
import { useReservationAPI } from "../../service/useReservationAPI";
import { Button, Card, CardActions, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GlobalStateContext } from "../../state/GlobalStateProvider";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import GetErrorSnackbar from "../../common/Snackbar";

const MyReservations = () => {
    const navigate = useNavigate();
    const { updateGlobalState, setPartOfGlobalState } = useContext(GlobalStateContext);
    const { getMyReservations } = useReservationAPI();
    const [reservations, setReservations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const fetchReservations = async () => {
        try {
            updateGlobalState({ reservationUuid: '' });
            const value = await getMyReservations();
            setReservations(value);
        } catch (reason) {
            handleFetchError('Mijn reservaties ophalen mislukt');
        } finally {
            setIsLoading(false);
        }
    };

    function handleFetchError(reason) {
        setSnackbarMessage(reason);
        setOpenSnackbar(true);
    }

    useEffect(() => {
        if (isLoading) {
            fetchReservations();
        }
    }, [isLoading]); // Ensures fetchReservations is called only once

    const columns: GridColDef[] = [
        {
            field: 'checkIn',
            headerName: 'Check-in',
            flex: 1,
            valueGetter: (params) => {
                const fromDate = params.row.fromDate;
                const checkInHour = params.row.checkInHour;
                return `${fromDate} ${checkInHour}`;
            },
        },
        {
            field: 'checkOut',
            headerName: 'Check-out',
            flex: 1,
            valueGetter: (params) => {
                const toDate = params.row.toDate;
                const checkOutHour = params.row.checkOutHour;
                return `${toDate} ${checkOutHour}`;
            },
        },
        {
            field: 'aantal',
            headerName: 'Voor',
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.cats.map(cat => cat.name).join(', ')}`;
            },
        },
        {
            field: 'stayTypes',
            headerName: 'Verblijf type',
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.stayTypes.join(', ')}`;
            },
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
        },
    ];

    function getRowId(row) {
        return row.uuid;
    }

    function clearReservationMetaAndNavigate() {
        setPartOfGlobalState('reservationMeta', {
            fromDate: undefined,
            toDate: undefined,
            catsInReservationIds: [],
            checkInHour: undefined,
            checkOutHour: undefined,
            stayTypes: []
        });
        navigate('/nieuwe-reservatie');
    }

    return (
        <>
            {isLoading && (<Loading />)}
            {!isLoading && (
                <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{textAlign: 'center'}}>
                    <Card variant="outlined" sx={{ width: '100%', maxWidth: '900px', mb: 2 }}>
                        <DataGrid
                            rows={reservations}
                            getRowId={getRowId}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}
                            pageSizeOptions={[5, 10]}
                            autoHeight
                            disableSelectionOnClick
                            disableColumnFilter
                            disableColumnMenu
                        />
                        <CardActions sx={{ justifyContent: 'flex-end' }}>
                            <Button variant="contained" onClick={clearReservationMetaAndNavigate}>
                                Maak een reservatie
                            </Button>
                        </CardActions>
                    </Card>
                    <GetErrorSnackbar openSnackbar={openSnackbar} snackbarMessage={snackbarMessage} setOpenSnackbar={setOpenSnackbar} />
                </Grid>
            )}
        </>
    );
}

export default withAuthenticationRequired(MyReservations, {
    onRedirecting: () => <Loading />,
    returnTo: '/nieuwe-reservatie'
});
