
import PersonForm from "./PersonForm";
import CatInformationForm from "./CatInformationForm";
import ReservationOverview from "./ReservationOverview";
import {useState} from "react";
import CatNameInput from "./CatNameInput";
import VeterinaryForm from "./VeterinaryForm";
import NewReservationPlanner from "./NewReservationPlanner";
import EmergencyContactForm from "./EmergencyContact";

function NewReservation() {
    const [currentStep, setCurrentStep] = useState(1);

    const updateCurrentStep = (step) => {
        setCurrentStep(step);
    };

    return (
        <div>
            {currentStep === 1 && (<CatNameInput updateCurrentStep={updateCurrentStep}/>) }
            {currentStep === 2 && (<NewReservationPlanner updateCurrentStep={updateCurrentStep}/>) }
            {currentStep === 3 && (<PersonForm updateCurrentStep={updateCurrentStep}/>) }
            {currentStep === 4 && (<VeterinaryForm updateCurrentStep={updateCurrentStep}/>) }
            {currentStep === 5 && (<CatInformationForm updateCurrentStep={updateCurrentStep}/>) }
            {currentStep === 6 && (<EmergencyContactForm updateCurrentStep={updateCurrentStep}/>) }
            {currentStep === 7 && (<ReservationOverview updateCurrentStep={updateCurrentStep}/>) }
        </div>
    )
}

export default NewReservation;
