import React, {useContext, useEffect, useState} from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../common/Loading";
import {Button, Grid} from "@mui/material";
import {useReservationAPI} from "../../service/useReservationAPI";
import {useSearchParams} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import ReservationPaid from "./feedback/ReservationPaid";
import ReservationPaymentFailed from "./feedback/ReservationPaymentFailed";
import ReservationPaymentCanceled from "./feedback/ReservationPaymentCanceled";
import ReservationProcessing from "./feedback/ReservationProcessing";
import ReservationPaymentOpen from "./feedback/ReservationPaymentOpen";
import GetErrorSnackbar from "../../common/Snackbar";

function PaymentProcessor() {
    const navigate = useNavigate();
    const {checkPaymentStatus} = useReservationAPI();
    const [searchParams] = useSearchParams();
    const [paymentStatus, setPaymentStatus] = useState("")

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    function handleFetchError(reason) {
        setSnackbarMessage(reason)
        setOpenSnackbar(true)
    }

    useEffect(() => {
        checkPaymentStatus(searchParams.get('reservation_id'))
            .then(value => setPaymentStatus(value))
            .catch(reason => handleFetchError('Ophalen van de betaal status mislukt, contacteer Catland.') );
    }, []);

    // TODO error handeling
    //     CANCELED, OK
    //     EXPIRED, -> Customer does not return to the site --> not to catch
    //     FAILED,
    //     PAID

    return (
        <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{textAlign: 'center'}}>
            <Grid item xs={12} sm={10} md={8} lg={6}>
                {paymentStatus === '' && (<ReservationProcessing/>)}
                {paymentStatus === 'paid' && (<ReservationPaid/>)}
                {paymentStatus === 'open' && (<ReservationPaymentOpen/>)}
                {paymentStatus === 'failed' && (<ReservationPaymentFailed/>)}
                {paymentStatus === 'canceled' && (<ReservationPaymentCanceled/>)}
            </Grid>
            <GetErrorSnackbar openSnackbar={openSnackbar} snackbarMessage={snackbarMessage} setOpenSnackbar={setOpenSnackbar} />
        </Grid>
    )
}


export default withAuthenticationRequired(PaymentProcessor, {
    onRedirecting: () => <Loading/>,
    returnTo: '/payment-processor'
});
