import React, {useEffect} from "react";
import {Button, Card, CardActions, CardContent, Grid, Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";

function Login() {
    const navigate = useNavigate();
    const {isAuthenticated, logout} = useAuth0();
    const hasRun = React.useRef(false);
    // logout({
    //     logoutParams: {
    //         returnTo: window.location.origin + '/login',
    //     }
    // }).then(r => {});

    useEffect(() => {
        // if (!hasRun.current) {
        //     logout({
        //         logoutParams: {
        //             returnTo: window.location.origin + '/login',
        //         }
        //     }).then(r => {
        //     });
        // }

        if (isAuthenticated) {
                logout({
                    logoutParams: {
                        returnTo: window.location.origin + '/login',
                    }
                }).then(r => {});
        }
    },[hasRun, isAuthenticated])

    return (
        <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{textAlign: 'center'}}>
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h6" color="secondary" gutterBottom sx={{mb: 2}}>
                        Log in om een reservatie te maken.
                    </Typography>
                </CardContent>
                <CardActions style={{marginBottom: '10px'}}>
                    <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Stack direction="column" spacing={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                className="mt-5"
                                onClick={() => window.location.href = 'https://www.kattenhotel-catland.be'}
                            >
                                Terug naar Catland
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className="mt-5"
                                onClick={() => navigate('/nieuwe-reservatie')}
                            >
                                Verder gaan naar inlogscherm
                            </Button>
                        </Stack>
                    </Grid>
                </CardActions>
            </Card>
        </Grid>)
}

export default Login;
