import React from 'react';
import {Navigate} from 'react-router-dom';
import {useAuth0} from "@auth0/auth0-react";

const ProtectedRoute = ({ roles, element: Component }) => {
    const {user} = useAuth0();
    if (!user) {
        // If not logged in, redirect to login page
        return <Navigate to="/nieuwe-reservatie"/>;
    }

    if (user) {
        const roles = user['https://tailvation.com/app_metadata'] || [];
    }

    if (roles && !roles.some(role => user['https://tailvation.com/app_metadata']?.roles.includes(role))) {
        // If user doesn't have required role, redirect or show an unauthorized page
        return <Navigate to="/unauthorized"/>;
    }

    return Component;
};


export default ProtectedRoute;
