import React, {useEffect, useState} from 'react';
import {Grid, Card, Button, Modal, Box, Typography, CardActions, CardHeader, CardContent, Select, MenuItem} from '@mui/material';
import {useReservationAdminAPI} from "../../service/useReservationAdminAPI";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {useNavigate} from "react-router-dom";
import GetErrorSnackbar from "../../common/Snackbar";
import EditIcon from '@mui/icons-material/Edit';

const DayView = ({selectedDay, toggleMonthDayView, refreshScreen}) => {
    const {getReservationsForDay, cancelReservation, updateReservation} = useReservationAdminAPI();
    const [reservations, setReservations] = useState([])
    const [currentUuid, setCurrentUuid] = useState(undefined);
    const navigate = useNavigate();

    const [open, setOpen] = React.useState(false);
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleOpenEditModal = (uuid) => {
        setCurrentUuid(uuid)
        setOpenEditModal(true);
    }
    const handleCloseEditModal = () => setOpenEditModal(false);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');


    function handleAnnuleer() {
        cancelReservation(currentUuid)
            .then(value => {
                setSnackbarMessage("Booking geannuleerd");
                setOpenSnackbar(true)
                handleClose();
                refreshScreen();
            })
    }

    function handleFetchError(reason) {
        setSnackbarMessage(reason)
        setOpenSnackbar(true)
    }

    function fetch(selectedDay) {
        if (!isNaN(selectedDay.getTime())) {
            getReservationsForDay(selectedDay)
                .then(value => {
                    setReservations(value)
                })
                .catch(reason => handleFetchError('Dag overzicht kon niet opgehaald worden'));
        }
    }


    const handleSelectChange = (event, field) => {
        const updatedReservations = reservations.map(reservation => {
            if (reservation.uuid === currentUuid) {
                return {...reservation, [field]: event.target.value};
            }
            return reservation;
        });
        setReservations(updatedReservations);
    };

    useEffect(() => {
        fetch(new Date(selectedDay));
    }, [selectedDay]);

    const handleUpdateHours = () => {
        updateReservation(reservations.find(value => value.uuid === currentUuid))
            .then(value => {
                handleCloseEditModal();
                refreshScreen();
            })
    }

    const renderActionButtons = (params) => {
        return (
            <strong>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{marginRight: 16}}
                    onClick={(event) => {
                        event.stopPropagation(); // don't select this row after clicking
                        setCurrentUuid(params.row.uuid)
                        handleOpen();
                    }}
                >
                    Annuleer
                </Button>
            </strong>
        )
    }

    const navigateWithUuidTo = (cat, path) => {
        navigate(`/${path}?uuid=${cat.uuid}`);
    }

    const columns: GridColDef[] = [
        {
            field: 'checkIn',
            headerName: 'Check-in',
            width: 200,
            renderCell: (params) => {
                return (
                    <span>
                        <EditIcon onClick={() => handleOpenEditModal(params.row.uuid)} style={{height: 15}}/>
                        {params.row.fromDate} {params.row.checkIn ? params.row.checkIn : 'null'}
                    </span>
                );
            },
        },
        {
            field: 'checkOut',
            headerName: 'Check-out',
            width: 200,
            renderCell: (params) => {
                return (
                    <span>
                        <EditIcon onClick={() => handleOpenEditModal(params.row.uuid)} style={{height: 15}}/>
                        {params.row.toDate} {params.row.checkOut ? params.row.checkOut : 'null'}
                    </span>
                );
            },
        },
        {
            field: 'verblijven',
            headerName: 'Verblijven',
            width: 100,
            valueGetter: (params) => {
                return `${params.row.stayTypes.map(stay => stay).join(', ')}`;
            }
        },
        {
            field: 'price',
            headerName: 'Prijs',
            width: 100,
            valueGetter: (params) => {
                return `${params.row.price} €`;
            }
        }, {
            field: 'advance',
            headerName: 'Voorschot *',
            width: 100,
            valueGetter: (params) => {
                return `${params.row.advance} €`;
            }
        },
        {
            field: 'aantal',
            headerName: 'Aantal Kat(ten)',
            width: 200,
            valueGetter: (params) => {
                return `${params.row.cats.map(cat => cat.name).join(', ')}`;
            },
            renderCell: (params) => {
                const cats = params.row.cats;
                return (
                    <span>
                {cats.map(cat => (
                    <a key={cat.id} onClick={() => navigateWithUuidTo(cat, 'mijn-katten')} style={{textDecoration: 'none', color: 'blue', marginRight: '5px'}}>
                        {cat.name}
                    </a>
                ))}
            </span>
                );
            },
        },
        {
            field: 'eigenaar',
            headerName: 'Eigenaar',
            width: 200,
            valueGetter: (params) => {
                return `${params.row.personInformation.name}`;
            },
            renderCell: (params) => {
                const name = params.value;
                const personInformation = params.row.personInformation; // Assuming you have an ID to link to
                return (
                    <a onClick={() => navigateWithUuidTo(personInformation, 'mijn-profiel')} style={{textDecoration: 'none', color: 'blue'}}>
                        {name}
                    </a>
                );
            },
        },
        {
            field: 'dierenarts', // The field name can be arbitrary since we're using valueGetter
            headerName: 'Dierenarts',
            width: 200,
            valueGetter: (params) => {
                return `${params.row.veterinaryDto.name}`;
            },
            renderCell: (params) => {
                const name = params.value;
                const veterinary = params.row.veterinaryDto; // Assuming you have an ID to link to
                return (
                    <a onClick={() => navigateWithUuidTo(veterinary, 'mijn-dierenarts')} style={{textDecoration: 'none', color: 'blue'}}>
                        {name}
                    </a>
                );
            },
        },
        {
            field: "action",
            headerName: "Acties",
            sortable: false,
            width: 300,
            renderCell: renderActionButtons
        },
    ];

    function getRowId(row) {
        return row.bookingNumber;
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Grid>
            <Card variant="outlined" style={{marginBottom: '10px'}}>
                <DataGrid
                    rows={reservations}
                    getRowId={getRowId}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {page: 0, pageSize: 7},
                        },
                    }}
                    pageSizeOptions={[7, 14]}
                    // checkboxSelection
                />
            </Card>
            <GetErrorSnackbar openSnackbar={openSnackbar} snackbarMessage={snackbarMessage} setOpenSnackbar={setOpenSnackbar} severity={"success"}/>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card sx={style}>
                    <CardHeader title={<Typography color="secondary" variant="h5">U staat op het punt een booking te annuleren.</Typography>}/>
                    <CardContent>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Weet u zeker dat u deze booking wil annuleren?
                        </Typography>
                    </CardContent>
                    <CardActions style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Button onClick={handleClose}>Nee</Button>
                        <Button onClick={handleAnnuleer}>Ja</Button>
                    </CardActions>
                </Card>
            </Modal>
            <Modal
                open={openEditModal}
                onClose={handleCloseEditModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card sx={style}>
                    <CardHeader title={<Typography color="secondary" variant="h5">U staat op het punt reservatie aan te passen.</Typography>}/>
                    <CardContent style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Box>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Check-in
                            </Typography>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                            </Typography>
                            <Select
                                value={reservations.find(value => value.uuid === currentUuid)?.checkIn || ""}
                                displayEmpty
                                inputProps={{'aria-label': 'Without label'}}
                                style={{marginTop: '10px'}}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,  // Adjust the height as needed
                                        },
                                    },
                                }}
                                onChange={(event) => handleSelectChange(event, 'checkIn')} // Add onChange handler
                            >
                                <MenuItem value=""><em>None</em></MenuItem>
                                {Array.from({length: 38}, (_, i) => {
                                    const hour = 5 + Math.floor(i / 2);
                                    const minute = i % 2 === 0 ? '00' : '30';
                                    const seconds = '00';
                                    return `${hour.toString().padStart(2, '0')}:${minute}:${seconds}`;
                                }).map(option => (
                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Check-out
                            </Typography>
                            <Select
                                value={reservations.find(value => value.uuid === currentUuid)?.checkOut || ""}
                                displayEmpty
                                inputProps={{'aria-label': 'Without label'}}
                                style={{marginTop: '10px'}}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,  // Adjust the height as needed
                                        },
                                    },
                                }}
                                onChange={(event) => handleSelectChange(event, 'checkOut')} // Add onChange handler
                            >
                                <MenuItem value=""><em>None</em></MenuItem>
                                {Array.from({length: 38}, (_, i) => {
                                    const hour = 5 + Math.floor(i / 2);
                                    const minute = i % 2 === 0 ? '00' : '30';
                                    const seconds = '00';
                                    return `${hour.toString().padStart(2, '0')}:${minute}:${seconds}`;
                                }).map(option => (
                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </CardContent>
                    <CardActions style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Button onClick={handleCloseEditModal}>Annuleren</Button>
                        <Button onClick={handleUpdateHours}>Opslaan</Button>
                    </CardActions>
                </Card>
            </Modal>
        </Grid>
    );
};

export default DayView;
