import React from "react";

import {useAuth0} from "@auth0/auth0-react";
import {Avatar, Box, Button, ClickAwayListener, Container, Grow, MenuItem, MenuList, Paper, Popper, Stack, Typography} from "@mui/material";

const Authentication = () => {
    const {
        user,
        isAuthenticated,
        loginWithRedirect,
        logout,
    } = useAuth0();

    const logoutWithRedirect = () =>
        logout({
            logoutParams: {
                returnTo: window.location.origin + '/login',
            }
        });

    function handleLoginIn() {
        loginWithRedirect();
    }

    function handleLoginOut() {
        logoutWithRedirect();
    }

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    return (
        <div className="nav-container">
            <Container>
                {isAuthenticated && (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        gap={2}
                    >
                            <Button
                                ref={anchorRef}
                                id="composition-button"
                                aria-controls={open ? 'composition-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleToggle}
                            >
                                <Typography variant="p" color="secondary">
                                    {user.nickname}
                                </Typography>
                                <Avatar sx={{width: 32, height: 32, marginLeft: 1}}>
                                    <img
                                        src={user.picture}
                                        alt="Profile"
                                        className="nav-user-profile d-inline-block rounded-circle mr-3"
                                        width="50"
                                    />
                                </Avatar>
                            </Button>
                            <Button
                                id="qsLoginBtn"
                                color="secondary"
                                variant="contained"
                                onClick={handleLoginOut}
                            >
                                Uitloggen
                            </Button>
                            {/*<Popper*/}
                            {/*    open={open}*/}
                            {/*    anchorEl={anchorRef.current}*/}
                            {/*    role={undefined}*/}
                            {/*    placement="bottom-start"*/}
                            {/*    transition*/}
                            {/*    disablePortal*/}
                            {/*>*/}
                            {/*    {({TransitionProps, placement}) => (*/}
                            {/*        <Grow*/}
                            {/*            {...TransitionProps}*/}
                            {/*            style={{*/}
                            {/*                transformOrigin:*/}
                            {/*                    placement === 'bottom-start' ? 'left top' : 'left bottom',*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            <Paper>*/}
                            {/*                <ClickAwayListener onClickAway={handleClose}>*/}
                            {/*                    <MenuList*/}
                            {/*                        autoFocusItem={open}*/}
                            {/*                        id="composition-menu"*/}
                            {/*                        aria-labelledby="composition-button"*/}
                            {/*                        onKeyDown={handleListKeyDown}*/}
                            {/*                    >*/}
                            {/*                        <MenuItem onClick={handleLoginOut}>Uitloggen</MenuItem>*/}
                            {/*                    </MenuList>*/}
                            {/*                </ClickAwayListener>*/}
                            {/*            </Paper>*/}
                            {/*        </Grow>*/}
                            {/*    )}*/}
                            {/*</Popper>*/}
                    </Box>)}

                {!isAuthenticated && (
                    <Button
                        id="qsLoginBtn"
                        color="primary"
                        onClick={handleLoginIn}
                    >
                        Inloggen
                    </Button>)}
            </Container>
        </div>)
}
export default Authentication;
