import {useAuth0} from "@auth0/auth0-react";
import {useContext} from "react";
import {GlobalStateContext} from "../state/GlobalStateProvider";
import {deepClone} from "@mui/x-data-grid/utils/utils";
import {toLocalDate} from "../common/DateUtil";

export function useReservationAPI() {
    const {getAccessTokenSilently} = useAuth0();
    const {globalState} = useContext(GlobalStateContext);

    const fetchWithAuth = async (url, options = {}) => {
        const accessToken = await getAccessTokenSilently();
        const headers = new Headers(options.headers || {});
        headers.append('Authorization', `Bearer ${accessToken}`);
        return fetch(url, {...options, headers});
    };

    const getMyReservations = async () => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations`;

            const response = await fetchWithAuth(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error checking reservation getMyReservations:', error);
            throw error;
        }
    };

    const getCatsInReservation = async (reservationUuid) => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations/${reservationUuid}/cats`;
            const response = await fetchWithAuth(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('There was a problem with the fetch getCatsInReservation:', error);
            throw error;
        }
    };

    const getReservationByUuid = async (reservationUuid) => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations/${reservationUuid}/overview`;
            const response = await fetchWithAuth(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching reservation getReservationByUuid:', error);
            throw error;
        }
    };

    const getStaysFromReservation = async (reservationUuid) => {
        if (reservationUuid !== '') {
            try {
                const url = `${process.env.REACT_APP_BACK_API}/reservations/${reservationUuid}/stays`;
                const response = await fetchWithAuth(url);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                return await response.json();
            } catch (error) {
                console.error('Error fetching reservation getStaysFromReservation:', error);
                throw error;
            }
        } else {
            return [];
        }
    };

    const getCheckInHourFromReservation = async (reservationUuid) => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations/${reservationUuid}/checkin`;
            const response = await fetchWithAuth(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.text();
        } catch (error) {
            console.error('Error fetching getCheckInHourFromReservation reservation overview:', error);
            throw error;
        }
    };

    const getCheckOutHourFromReservation = async (reservationUuid) => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations/${reservationUuid}/checkout`;
            const response = await fetchWithAuth(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.text();
        } catch (error) {
            console.error('Error fetching reservation overview:', error);
            throw error;
        }
    };

    const createReservation = async (reservationMeta) => {
        // DATE IS OFF, CAN4T HANDLE IT ON FORM becuase of reactivity
        let deepClone1 = deepClone(reservationMeta);
        deepClone1.fromDate = toLocalDate(reservationMeta.fromDate);
        deepClone1.toDate = toLocalDate(reservationMeta.toDate);
        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations`;
            const response = await fetchWithAuth(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(deepClone1)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.text();
        } catch (error) {
            console.error('Error booking reservation:', error);
            throw error;
        }
    };

    const updateReservation = async (reservationUuid, reservationMeta) => {
        let deepClone1 = deepClone(reservationMeta);
        deepClone1.fromDate = toLocalDate(reservationMeta.fromDate);
        deepClone1.toDate = toLocalDate(reservationMeta.toDate);
        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations/${reservationUuid}`;
            const response = await fetchWithAuth(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(deepClone1)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.text();
        } catch (error) {
            console.error('Error booking reservation:', error);
            throw error;
        }
    };

    const confirmReservation = async (reservationUuid) => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations/confirm/${reservationUuid}?paymentReceived=true`;
            const response = await fetchWithAuth(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({})
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.text();
        } catch (error) {
            console.error('Error booking reservation:', error);
            throw error;
        }
    };

    const getPaymentLink = async (reservationUuid) => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations/checkout/${reservationUuid}`;
            const response = await fetchWithAuth(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.text();
        } catch (error) {
            console.error('Error booking reservation:', error);
            throw error;
        }
    };

    const checkReservationAvailability = async (fromDate, toDate, numberOfPets) => {
        try {
            const queryParams = new URLSearchParams({fromDate, toDate, numberOfPets}).toString();
            const url = `${process.env.REACT_APP_BACK_API}/reservations/availability?${queryParams}`;

            const response = await fetchWithAuth(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error checking reservation checkReservationAvailability:', error);
            throw error;
        }
    };


    const getAvailableStays = async (fromDate, toDate) => {
        try {
            const queryParams = new URLSearchParams({fromDate, toDate}).toString();
            const url = `${process.env.REACT_APP_BACK_API}/reservations/stays?${queryParams}`;

            const response = await fetchWithAuth(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error checking reservation getAvailableStays:', error);
            throw error;
        }
    };

    const getPickUpDays = async (toDate) => {
        try {
            const queryParams = new URLSearchParams({toDate: toDate}).toString();
            const url = `${process.env.REACT_APP_BACK_API}/reservations/pick-up?${queryParams}`;

            const response = await fetchWithAuth(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error checking reservation getPickUpDays:', error);
            throw error;
        }
    };

    const getDeliverDays = async (fromDate) => {
        try {
            const queryParams = new URLSearchParams({fromDate: fromDate}).toString();
            const url = `${process.env.REACT_APP_BACK_API}/reservations/deliver?${queryParams}`;

            const response = await fetchWithAuth(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error checking reservation getDeliverDays:', error);
            throw error;
        }
    };

    const checkPaymentStatus = async (reservationUuid) => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/reservations/checkout/${reservationUuid}/validate`;
            const response = await fetchWithAuth(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error checking reservation checkPaymentStatus:', error);
            throw error;
        }
    };

    return {
        getMyReservations,
        getCatsInReservation,
        getStaysFromReservation,
        getReservationByUuid,
        createReservation,
        updateReservation,
        checkReservationAvailability,
        getAvailableStays,
        getPickUpDays,
        getDeliverDays,
        getCheckInHourFromReservation,
        getCheckOutHourFromReservation,
        getPaymentLink,
        checkPaymentStatus,
        confirmReservation
    };
}
