import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../../common/Loading";
import React, {useContext, useEffect, useState} from "react";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import nl from 'date-fns/locale/nl';
import {Alert, Button, Card, CardActions, CardContent, CardHeader, Typography} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import {GlobalStateContext} from "../../../state/GlobalStateProvider";
import {toLocalDate} from "../../../common/DateUtil";


function CheckInDate({reservationStart, reservationEnd, updateReservationStep}) {
    const {globalState, updateGlobalState} = useContext(GlobalStateContext);
    const [dateUnavailable, setDateUnavailable] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (globalState.reservationMeta.fromDate) {
            setDateUnavailable(globalState.reservationMeta.fromDate < reservationStart);
        }
        setIsLoading(false);
    }, [globalState.reservationMeta.fromDate, reservationStart]);

    return (
        <>
            <Card>
                <CardHeader title={<Typography color="secondary" variant="h5">Selecteer de check-in datum</Typography>}/>
                <CardContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
                        <DatePicker
                            label="Check-in datum"
                            value={globalState.reservationMeta.fromDate}
                            minDate={reservationStart}
                            maxDate={reservationEnd}
                            onChange={(newValue) => {
                                updateGlobalState({reservationMeta: {fromDate: newValue}})
                            }}
                            renderInput={(params) => <TextField {...params} disabled/>}
                        />
                    </LocalizationProvider>
                    {dateUnavailable && (<Alert sx={{marginTop: 2}} severity="warning">Check-in ligt in het verleden en is niet meer beschikbaar!</Alert>)}
                </CardContent>
                <CardActions style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <>
                        <Button onClick={() => updateReservationStep(-1)} variant="contained">
                            Vorige
                        </Button>
                        <Button disabled={dateUnavailable || !globalState.reservationMeta.fromDate} variant="contained"
                                onClick={() => updateReservationStep(2)}>
                            Volgende
                        </Button>
                    </>
                </CardActions>
            </Card>
        </>
    )
}

export default withAuthenticationRequired(CheckInDate, {
    onRedirecting: () => <Loading/>,
    returnTo: '/nieuwe-reservatie'
});
