export const getMonthStartAndEndPlusOutsideDays = (month, year) => {
    const startDate = new Date(year, month - 1, 1);
    startDate.setDate(startDate.getDate() - 7);
    const endDate = new Date(year, month, 0);
    endDate.setDate(endDate.getDate() + 7);

    const formatStartDate = toLocalDate(startDate);
    const formatEndDate = toLocalDate(endDate);

    return {startDate: formatStartDate, endDate: formatEndDate};
};

export function toLocalDate(startDate) {
    return `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
}
